import React from "react"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div
      style={{
        textAlign: "center",
        marginBottom: `1.45rem`,
        marginTop: `1.45rem`,
      }}
    >
      <Image
        style={{
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          maxWidth: "600px"
        }}
      />
    </div>
    <div
      style={{
        textAlign: "center",
        fontFamily: "'Raleway', sans-serif",
        fontSize: "1.5em",
      }}
    >
      <span style={{ color: "grey" }}>Portfolio </span>
      <span style={{ color: "black" }}>coming </span>
      <span style={{ color: "grey" }}>soon</span>
    </div>
    {/* <Link to="/page-2/">Go to page 2</Link> <br />
    <Link to="/using-typescript/">Go to "Using TypeScript"</Link> */}
  </Layout>
)

export default IndexPage
